import React, { Component } from 'react';

import Course from '../components/Course';

export class Workshop extends Component {
  render() {
    return <Course />;
  }
}

export default Workshop;
