import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Icon from '@material-ui/core/Icon';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid
} from '@material-ui/core';

import { Mobile, Desktop, Tablet, Default } from '../util/Breakpoints';

// TODO: Fetch CV through api and store in Redux
export class CV extends Component {
  state = {
    numPages: null,
    pageNumber: 1
  };

  renderEducation() {
    return (
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant='h6'>Education</Typography>
        <List dense>
          <ListItem>
            <ListItemText
              primary='1996: Master of Fine Arts'
              secondary='San Francisco Art Institute, California'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1992: Bachelor of Fine Arts'
              secondary='San Francisco Art Institute, California'
            />
          </ListItem>
        </List>
        {this.renderStipends()}
      </Grid>
    );
  }

  renderStipends() {
    return (
      <React.Fragment>
        <Typography variant='h6'>Stipends, Residency & Commissions</Typography>
        <List dense>
          <ListItem>
            <ListItemText
              primary='2013: Residency'
              secondary='Knyazha Hora, Ukraine'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2013: Commission for Graton Racheria casino'
              secondary='Sonoma'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2013: Commission MGM Grand Hotel'
              secondary='Las Vegas'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2010: Large painting installation'
              secondary=' Commission for Vanguard Cinema LA'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2005: Commission for Red Rock Hotel and Casino, 9 large paintings'
              secondary='Las Vegas'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2001: Commission for Marel, 10 large paintings'
              secondary='Knyazha Hora, Ukraine'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2000: Two years stipends'
              secondary='Icelandic Government'
            />
          </ListItem>
        </List>
      </React.Fragment>
    );
  }

  renderPrivateExhibitions() {
    return (
      <Grid item xs={12} sm={6} lg={3}>
        <List dense>
          <Typography variant='h6'>Private Exhibitions</Typography>
          <List dense>
            <ListItem>
              <ListItemText
                primary='2016: The void draws it self up, picture, shadow of nothing'
                secondary='Anarkía, Art Gallery, Kópavogur'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2016: September 2013. ( in collaboration with Jón Óskar)'
                secondary='Anarkía, Art Gallery, Kópavogur'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2015: Collision'
                secondary='Gallery Mokka, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2014: Comlementary opposites'
                secondary='Anarkía Art Gallery, Kópavogur'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2013: September. ( In collaboration with Jón Óskar)'
                secondary='Kattle house,
        Akureyri Art Museum, Akureyri'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2013: Explosions'
                secondary='Anarkía Art Gallery, Kópavogur'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2013: Flaying a butterfly'
                secondary='Anarkía Art Gallery, Kópavogur'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2013: Black soaring cuts'
                secondary='Anarkía Art Gallery, Kópavogur'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2012: Spinal Sweep'
                secondary=' S Cube Gallery, Laguna beach, California'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2011: November'
                secondary='Reykjavík Art Gallery, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2011: Beauty and the fool'
                secondary=' Raykjavík Art Gallery, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2010: Painting performance, dialogue with Scandinavian and Asian
        poets'
                secondary='Nordic house, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2010: Tenebrous Flesh'
                secondary='Reykjavík Art Gallery, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2009'
                secondary='The Icelandic Printmaking association, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2007: Innards'
                secondary='Anima Gallery, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2006: Lucky Stroke'
                secondary='Anima Gallery, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2005: Luminous Darkness'
                secondary='Hafnarborg Municipial Museum, Hafnarfjörður'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2004'
                secondary='Next Gallery, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2004: Opus'
                secondary='Gerðarsafn Municipial Museum, Kópavogur'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2004: Opus'
                secondary='Akureyri Art Museum, Akureyri'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2003: Half a Dozen Rose'
                secondary='Gallery (part of Los Angeles international
          biennial'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2003: New works'
                secondary='Santa Monica Studios, Los Angeles'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2002: Ekkert/Nothing'
                secondary='Nordic House, the Faroe Islands'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2001: Ekkert/Nothing'
                secondary='Hafnarborg Municipial Museum, Hafnarfjörður'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='2000: Door to the shadow of water'
                secondary='Gallery Hlemmur, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1997: Stimulation to the Core'
                secondary='Tuttugu fermetrar, gallery, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1997: Pleasuredome'
                secondary='Gallery Sjónarhóll, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1996: Waiting room'
                secondary='Gallery Við Hamarinn, Hafnarfjörður'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1995: Scar tissue'
                secondary='Gallery Mokka, Reykjavík'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1992: Ginnungagap'
                secondary='Mace (Space for Art), San Francisco'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1992'
                secondary='Gallery Mokka, Reykjavík'
              />
            </ListItem>
          </List>
        </List>
      </Grid>
    );
  }

  renderCollectiveExhibitions() {
    return (
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant='h6'>Collective Exhibitions</Typography>
        <List dense>
          <ListItem>
            <ListItemText
              primary='2015: Just painted II'
              secondary='Kjarvalsstaðir, Reykjavík'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2014: Cocktail at the port'
              secondary='Gallery Port, Kópavogur'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2012: Hangman´s Rock-The Delirium of Vision'
              secondary='Kjarvalstaðir, Reykjavík'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2011: From the museum collection – Summer exhibition'
              secondary='Gerðarsafn
    Municipial Museum, Kópavogur'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2008: Horizons'
              secondary='Gerðarsafn Municipial Museum, Kópavogur'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2006: The gentle lava'
              secondary='Hafnarborg Municipial Museum, Hafnarfjörður'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2005: HP Garcia Gallery summer exhibition'
              secondary='New York'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2004: IV international biennial of Drawing'
              secondary='Pilsen, Czech'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2004: New Aquisition'
              secondary='Gerðarsafn Municipial Museum, Kópavogur'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2003: New Acquisition'
              secondary='Hafnarborg Municipial Museum, Hafnarfjörður'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2003: Mynd, Henie Onstandt'
              secondary='Oslo, Norway'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2002: Hlemmur.is'
              secondary='Gerðarsafn Municipial Museum, Kópavogur'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2002: New Aquisition'
              secondary='Reykjavík Art Museum, Harbour House'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2003: Mynd'
              secondary='Reykjavík Art Museum, Harbour House'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2000: Orgasm 2000'
              secondary='Akureyri Art Museum, Akureyri'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2000: The Seven Virtues in Ancient and Modern Times'
              secondary='Stekkjargjá,
    Thingvellir'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='2000: The Seven Virtues in Ancient and Modern Times'
              secondary='Akureyri Art
    Museum'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1999: Elements'
              secondary='Gerðarsafn Municipial Museum, Kópavogur'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1999: Elements, Christians fjerde bibliotek'
              secondary='Rundetårn Copenhagen,
    Danmark'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1998: Painting the Limit'
              secondary='Hafnarborg Municipial Museum, Hafnarfjörður'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1998: In the flesh'
              secondary='the Living art Museum, Reykjavík'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1998: U.M.'
              secondary='Listaskálinn Gallery in Hveragerði, Iceland'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1998: Gallery Sævar Karl'
              secondary='Reykjaví, Iceland'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1997: Iclandic Art ’97'
              secondary='Reykjavík Art Museum, Harbour House'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1997: Male to Male'
              secondary='Gallery Mokka, Reykjavík'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1996: Thirteen Artists'
              secondary='Community Center of Grindavík, Iceland'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1995: Unity Trancending Boundaries'
              secondary='Somar Gallery, San Francisco'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1995: The Strong Clear Line-Nordic 5 Arts'
              secondary='Thimonthy Higbee Gallery,
    San Francisco'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1994: Walter Macbean Gallery Spring Show'
              secondary='San Francisco Art Institute'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='1992: South Beach Billjard “Figures”'
              secondary='San Francisco'
            />
          </ListItem>
        </List>
      </Grid>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          {this.renderEducation()}
          {this.renderPrivateExhibitions()}
          {this.renderCollectiveExhibitions()}
        </Grid>
      </React.Fragment>
    );
  }
}

export default CV;
