import axios from 'axios';

import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_LOADING_UI,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_CAPTCHA,
  SET_EMAIL_SENT
} from '../types';

export const loginUser = (userData, navigate) => dispatch => {
  dispatch({ type: SET_LOADING_UI, payload: { loading: true } });

  axios
    .post('/login', userData)
    .then(res => {
      setAuthorizationHeader(res.data.token);

      dispatch({ type: SET_AUTHENTICATED });
      dispatch({ type: SET_LOADING_UI, payload: { loading: false } });
      dispatch({ type: CLEAR_ERRORS });

      navigate('../home');
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

export const submitCaptcha = data => dispatch => {
  axios
    .post('/verifycaptcha', data)
    .then(res => {
      console.log(res.data);
      dispatch({
        type: SET_CAPTCHA,
        payload: { captchaVerified: res.data.success }
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const sendEmail = data => dispatch => {
  axios
    .post('/email', data)
    .then(res => {
      dispatch({
        type: SET_EMAIL_SENT,
        payload: { emailSent: true }
      });
    })
    .catch(err => {
      dispatch({
        type: SET_EMAIL_SENT,
        payload: { emailSent: false }
      });
    });
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem('FBIdToken');
  delete axios.defaults.headers.common['Authorization'];
  dispatch({ type: SET_UNAUTHENTICATED });
};

const setAuthorizationHeader = token => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common['Authorization'] = FBIdToken;
};
