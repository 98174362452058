import React, { Component } from "react";
import PropTypes from "prop-types";
import { navigate, Redirect } from "@reach/router";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";

import { validateLoginData } from "../util/validators";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errors: {},
      loading: false
    };

    //this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /*componentWillMount() {
    if (this.props.authenticated) {
      console.log('test1');
      return navigate('admin/home');

      console.log('returning');
      //return <Redirect to='home' />;
    }
  }*/

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  /*handleLogin(e) {
    e.preventDefault();

    const { navigate } = this.props;
    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    axios
      .post('/login', userData)
      .then(response => {
        navigate('home');
      })
      .catch(error => {
        this.setState({ errors: error });
      })
      .finally(() => {
        //console.log(this.state.images);
      });
    
  }*/

  handleChange(event) {
    event.preventDefault();

    this.setState({ [event.target.type]: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();

    const { navigate } = this.props;
    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    const { valid, errors } = validateLoginData(userData);

    if (!valid) {
      this.setState({
        errors: errors
      });
      return;
    }

    /*this.setState({
      loading: true,
      errors: {}
    });*/
    this.setState({ errors: {} });
    this.props.loginUser(userData, navigate);
    /*axios
      .post('/login', userData)
      .then(res => {
        localStorage.setItem('FBIdToken', `Bearer ${res.data.token}`);
        this.setState({
          loading: false
        });
        navigate('home');
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      })
      .finally(() => {
        //console.log(this.state.images);
      });*/
  };

  render() {
    const { errors } = this.state;
    const {
      authenticated,
      UI: { loading }
    } = this.props;

    if (authenticated) {
      return <Redirect noThrow to="admin/" />;
    }

    return (
      <div className="login-container">
        <div className="login-content">
          <Typography variant="h2" className="login-header">
            Login
          </Typography>

          <form noValidate onSubmit={this.handleSubmit} className="login-input">
            <TextField
              id="outlined-email-input"
              label="Email"
              className="login-input__item"
              type="email"
              name="email"
              helperText={errors.email}
              error={errors.email ? true : false}
              autoComplete="email"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
            />
            <TextField
              id="outlined-password-input"
              label="Password"
              className="login-input__item"
              type="password"
              name="password"
              helperText={errors.password}
              error={errors.password ? true : false}
              autoComplete="current-password"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
            />
            {errors.general && (
              <Typography variant="body2" className="login-input__error">
                {errors.general}
              </Typography>
            )}
            <Button
              variant="contained"
              type="submit"
              className="login-input__button"
              disabled={loading}
            >
              Login
              {loading && (
                <CircularProgress size={30} className="login-input__loading" />
              )}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
  UI: state.UI
});

const mapActionsToProps = { loginUser };

export default connect(mapStateToProps, mapActionsToProps)(Login);
