import React, { Component } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

export class Texts extends Component {
  renderTexts = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Tenebrous flesh</Typography>
          <List dense>
            <ListItem>
              <ListItemText primary="The making of art is a physical activity, a kind of panmixia of the agent with his own urges. It is rooted in an aesthetic longing where uncertainty is welcomed, research and rhetorics are cast out of the house of art – words are overwhelmed by touch and perception." />
            </ListItem>
            <ListItem>
              <ListItemText primary="There are a number of uses that words – or dicourse – can be put to. Discourse can, among other things, be an effective tool for concealing meaning, it evens out irregularities in thought and sense that seem to be getting out of hand. 'What we cannot speak about we must pass over in silence', Ludwig Wittgenstein remarked many years ago, and ever since the intellectuals of the world have been citing these words feverishly – without it having a notable effect on their verbosity though. Even Wittgenstein himself was unable to heed his own advice, and after a short pause he resumed his loquacity. Discourse obscures meaning by maintaining the misconception that thought and meaning reside in words. 'In the beginning was the Word', we hear John the evangelist shout from the Middle East, but a faint echo sounds from the distant past of our own part of the world: In the beginning there was void, darkness – and flesh." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Meaning, what we call the world, what we name and discuss at length, arises where flesh meets the dark void. BS's paintings are such collision sites. The violent eruptions of red hues on the surface of these huge metal sheets strengthen the associaton with the dissection of flesh, as if we were looking at enormous cleavers, hacking away at the tenebrious flesh of the universe." />
            </ListItem>
          </List>

          <Typography variant="h6">
            From an interview with the artist
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText primary="Your paintings have a very physical presence." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Physical experience is something I aim for in my paintings. The act of painting itself is very physical and my movements are determined my physical size and capabilities: Large sweeping movements and small ones, quick or soft to determine the patterns and forms that emerge. To this is added the action of the materials as I mix oil paints and water." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Yes, this is what gives us the impression that the paintings have to some degree painted themselves." />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="I mix the oils in different solutions and the choice of colours is also very important. Lately I have been using colour scales of the same colour. These can nonetheless be very varied to provide a chromatic depth to the paintings. This also accounts for the clear gradations that appear when I start to agitate the mixtures on the surface of the painting.
I also use a lot of water. The plexiglass has sides glued onto the main surface so as to form a shallow tray when it is laid down on the floor. With the liquid mixture floating in this tray the slightest difference in the level of the floor starts to affect the painting as the water, which is heavier, collects in the lowest places."
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="How much do you then intervene in the process?" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="I see myself as a gardener who sets something growing and then tries to tend it as best he can. I assist the painting in its own growth and do not need to have absolute control. Each movement is determined by the one before and forms a web of strokes on the surface of the painting. There are no plans or sketches, nothing is predetermined except the size, the colours and the materials used.
The painting on which I work is actually the backside of the painting as it will be exhibited. I don't see the final painting until it as finished and I can watch it as a spectator, a perspective totally different from the one that is involved in making the painting."
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="The actual mounting is really part of the work, is it not?" />
            </ListItem>
            <ListItem>
              <ListItemText primary="When it is mounted on the wall the side that I worked on is turned to the wall and the white wall - a neutral space, nothing - reflects light though the semi-transparent painting. The image appears as shadows reflected from the white wall, reflections of nothing which nonetheless can define it. The Chinese think of the white paper and nothing, an emptiness, and define it as space in a few strokes. I want my paintings to have this association with emptiness and light, to grow in the light. I think of a comment from Jean Arp about the Concrete artists: 'These artists do not want to copy nature. They do not wish to reproduce but to produce. They wish to produce as a plant wish produces a fruit and is unable to produce a still life, a landscape, or a nude.'" />
            </ListItem>
            <ListItem>
              <ListItemText primary="So the title of the exhibition in Hafnarborg is a kind of manifesto, is it?" />
            </ListItem>
            <ListItem>
              <ListItemText primary="I wish to emphasise that the creation of the painting and the painting itself are physical processes. That is why I describe my method in such detail. The physical gestures are synonymous with the thought that goes into the painting, they are the thoughts. In the case of the larger paintings I am even standing in the painting as I am working on it, so my whole posture and every movement enters into it. The movement is thought and the though is like a movement, a gesture which accomplishes meaning, and this is really just an illustration of being in the world is like. The philosopher Merleau-Ponty always insisted on this gestural perspective on our being saying: 'We must ... avoid saying that our body is in space or in time. It inhabits space and time.' The actions of the painter exemplify this.'" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Merleau-Ponty's work on the phenomenology of perception turns on the idea of the 'body subject', the imbodied subject." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Yes, he insists that we cannot separate our percieved world and our perspectival placement in it from our idea of ourself. We are not abstract beings looking out from someplace inside our heads through our eyes into the world. Instead we are always engaged with the world. The outcome of my work is that each viewer sees in effect his own aesthetic solution to his own situation. I myself am most pleased when the paintings surprise me and that is one reason why I work with the insoluable elements of oil and water." />
            </ListItem>
            <ListItem>
              <ListItemText primary="So that is also why you relinguish some of the intellectual and aesthetic control over the process." />
            </ListItem>
            <ListItem>
              <ListItemText primary="It is analogous to walking without knowing where you will end up or what you will encounter along the way, or to planting a seed and nursing it without knowing what will grow from it. The plastic base precludes any invisible corrections, the process and the result are one and the same and everything is visible and transparent. Insofar as the works express something or say something they express that gestural language which carries from the gesture of painting the surface to the gestures of the viewer as he examines it from his perspective in the gallery or museum." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Your work is remeniscent of the heroic abrstaction of the New York school but at the same time it comes close to a kind of minimalism with the almost monocramatic surfaces and the choice of an industrial material as a base. Is this a contradiction?" />
            </ListItem>
            <ListItem>
              <ListItemText primary="I don't see my work as a statment against painting as a traditional medium or as any sort of statement at all. I aim for a transparent presentation of my ideas as embodied in the process, there is no ironic gesture involved. I try to achieve a kind of nearness or presence in my work which I hope to communicate to the audience." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Jón Proppé" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            Transparent Solutions: The Work of Bjarni Sigurbjörnsson By Jón
            Proppé
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText primary="In his recent paintings Bjarni Sigurbjörnsson has moved towards an almost monochromatic presentation, somewhat to the surprise of those who had seen his earlier vibrant compositions of clashing colours and forms. On closer consideration, however, working with a single colour is entirely consistent with what has always been the most striking aspect of Sigurbjörnsson's work, namely his unshakable confidence in the expressive power of painting, unadorned and without the aid of representations or external associations. Abstraction relies on such absolute confidence in the painting itself." />
            </ListItem>
            <ListItem>
              <ListItemText primary="It needs no referent, no historical or mythological associations, and no representational content. Form and colours suffice. Apart from this shift, Sigurbjörnsson's new works show a continued concern with revealing the inner workings of the painting, going so far as to make the painting almost wholly transparent. As every painter knows, what goes into a painting is as important as what is shown on its surface. In addition to the pictorial elements that litter its top or outer skin every painting contains a mass of material that remains unseen. These are the layers of material that form the complex inner life of the painting; the organs that sustain the body and lend substance to the picture that appears on its skin." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Bjarni Sigurbjörnsson takes us beneath the surface of the painting. Instead of using wood or canvas he paints on a transparent medium and then presents the back of the painting to the viewer. In effect he shows us the painting from the point of view of the canvas. The surface is a subface, revealing the innards of a painting while the actual surface - in this case the back of the painting as it is presented to the viewer - is no more relevant to the artwork than the primer is to a conventional painting. We are accustomed to thinking of the layers beneath the surface of a painting as mere preparation - the invisible foundation that has no voice of its own and is nothing but the tedious though necessary preamble to the poetry of the surface. Bjarni Sigurbjörnsson shows us that these inner depths have a life of their own." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seen from this perspective the painting appears as a primordial soup of paints and solvents, a seething chemical battlefield where forms blossom forth in a nourishing sea of poison. Bjarni Sigurbjörnsson's paintings show us that this expressive force is not simply a function of the visible pattern of the painting's immediate surface." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Instead it pervades the entire painting, layer by layer, as it informs the painter's efforts at every stage. In effect his treatment of the painting constitutes a new turn in painting, a conceptual shift that does not involve the rejection of the painting - as so many recent innovations in art have done - but relies rather on the careful analysis and understanding of the painting's own possibilities." />
            </ListItem>
            <ListItem>
              <ListItemText primary="These paintings tempt one reflect on certain basic questions relating to painting, its history, presentation and purpose." />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Indeed, they seem to require that one consider them in the context of the radical shifts that have transformed painting itself and our understanding of paintings in this century. Sigurbjörnsson's work is firmly based in the avant-garde but the absence of any form of compromise, of even a nod to the concept-based-minimalism that now fills our museums and galleries, invites comparison not with his contemporaries but with the very artists who initiated avant-garde in the early decades of the twentieth century.
                  Given the present ideological state of the art world it is also necessary to provide a context for a project that will seem to many to be an anachronism. The questions raised by Sigurbjörnsson's work echo the concerns of the early Constructivists such as the Russian artist Naum Gabo (1890-1977) who argued that abstraction marked not only a departure from the established modes of painting, but a new beginning. He thought it revealed a new universal law and he wrote that: 'The revelation of this fundamental law has opened up a vast new field in art giving the possibility of expression to those human impulses and emotions which have been neglected.' What impulses and emotions, one is then bound to ask, had been neglected? A popular view has been that abstract painting somehow captures, reflects or imitates our inner life, the formless turmoil emotions and subconscious drives that are largely suppressed or at least sublimated in representational or narrative art.
                  But such explanations only defer the problem, shifting the question of representation inward but leaving it intact, saying, in effect, that though abstract paintings do not represent the visual world they still represent nature. Such explanations did not suit Gabo and his friends. Instead what Gabo seems to have meant was that the 'painterly' element in painting had been neglected, that the sheer expressiveness of the painter's simplest yet most delicate tools - the stroke, the hue, the shape itself - had been neglected. And if that was how he felt, then he was right to."
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Insofar as painting is reduced to being a mere handmaiden of some further and unrelated meaning it remains an insignificant craft - a prosaic tool that can attain no poetry of its own. To the early abstract painters stroke, hue and shape were not merely tools to be used for some external purpose. Rather, these elements were seen to 'possess their own force of expression independent of any association with the external aspects of the world', as Naum Gabo put it." />
            </ListItem>
            <ListItem>
              <ListItemText primary="This sort of thing required a radical change in thinking and it took some getting used to." />
            </ListItem>
            <ListItem>
              <ListItemText primary="In the late 1920s it was still so unconventional that it required another manifesto, this time from the Dutch painter and architect Theo Van Doesburg (1883-1931) who declared in his manifesto for Concrete Art that 'A pictorial element has no other significance than 'itself' and therefore the picture has no other significance than 'itself''. What Van Doesburg is suggesting is that the elements of a picture are not its content but rather the strictly visible and measurable parts - lines, colours and shapes; the very elements which had hitherto fore been seen as mere building blocks or tools for the painter to use. Abstraction - taking leave from art history for a moment - is to some degree an epistemological issue, something that has to do with our knowledge or with what we can know." />
            </ListItem>
            <ListItem>
              <ListItemText primary="In painting it is by no means merely an issue of style or trend, but something that cuts to the very root of our being ... to the very roots of our being as creatures who enjoy art, at any rate. It does so, again, by denying the transparent shift from medium to referent - by interrupting the easy flow from the word 'cow' to the lazy bovine source of milk grazing in the pasture, or from the picture of a cow to that same idyllic experience of grazing cattle." />
            </ListItem>
            <ListItem>
              <ListItemText primary="The radicalism that denies us the convenience of transparent mediation - that brings to our attention the tools that we use to refer to things and denies us the comfort of considering only the things to which these tools refer - this radicalism is probably the most liberating innovation of our century but at the same time the most perplexing. Abstract painting became possible not because artists had ceased to believe in painting and felt that something new was needed but precisely because they had unshakable confidence in painting, the sort of confidence that we have found in the work of Bjarni Sigurbjörnsson." />
            </ListItem>
            <ListItem>
              <ListItemText primary="In the context of the art world today this attitude is rare and many artists, it seems, find it difficult to maintain. But those who do can produce results that are every bit as striking as the work of the early avant-gardistes or the expressive gestures of the painters of New York School. Bjarni Sigurbjörnsson's early paintings were a revelation of sorts to the viewer, allowing a glimpse of the hidden world inside the painting." />
            </ListItem>
            <ListItem>
              <ListItemText primary="The transparent medium on which he painted became a window into the unstable world of paints and chemicals in which the painting is formed by a kind of controlled chance. These works were in fact remarkably aggressive, challenging the viewer to find beauty in the nether layers of the painting, as if he were being invited to admire the world of earthworms and beetles beneath the beautiful garden blooming above the ground. The paintings become in effect a kind of test or experiment, questioning the boundaries of our aesthetic by displaying what should 'properly' be hidden. The current paintings return to the chemical mysteries that Sigurbjörnsson explored in his early works." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Working with thin-flowing oil paints mixed with water, he allows these opposites to shape through their reaction the forms of the painting. As the liquids dry what remains is in effect a documentation of their struggle to achieve an impossible solution, the solution of oil and water. When hung, the panels are raised from the wall allowing light to enter the painting from behind so that the thin layer of colour becomes luminescent. The effect is quite striking, giving a sense of incredible clarity and brilliance. The experience of seeing these paintings, as one viewer pointed out, is not unlike seeing a large Cibachrome print for the first time. The large panels seem to document a momentous engagement, a heroic battle involving the entire surface, yet every detail emerges with perfect clarity." />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="In one sense these painting can be seen as the logical result of the avant-gardiste's occupation with the nature and possibilities of the paint itself; here the paint is literally the very agent that creates the 'picture', with only minimal interference from the painter. But in fact Sigurbjörnsson goes much further than that.
The element of chance is really only incidental to the paintings, providing a focus for a bold statement that is entirely expressive and entirely controlled. The creative energy that goes into these paintings is concentrated in one decisive sweep, a single confident gesture by the painter."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Thus they speak not only of some abstract ideas about the relevance of form and colour but of the very act of painting. They speak of the enormous risk that any painter takes when he takes his brush to the canvas - a risk that he must take with complete confidence if the outcome is to be successful.
As is the case with any painter worth his salt, this creative risk is the prime focus of Sigurbjörnsson's work. It is an eternal problem that is reflected not only in art but in any creative enterprise, whenever one seeks to make something where there was nothing before or to change the world from one state to another. It appears in the paradox of Alexander and the turtle where, through Sophistic logic, we can demonstrate the absolute impossibility of explaining any such change in the state of the world. As in the case of Alexander, the solution in painting is always gestural: The painter overcomes the paradox of creation by a decisive gesture that precludes any doubt about the outcome."
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Jón Proppé (b. 1962) studied philosophy at the University of Illinois, Champaign-Urbana, and has since worked as an editor, writer and lecturer on art, philosophy and cultural studies. Jón Proppé has also worked as a curator and taken part in various projects in the field of art and culture." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Parts of this essay were first published in a catalogue on Bjarni Sigurbjörnsson's work in 1999.Gegnsæjar " />
            </ListItem>
          </List>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Grid container spacing={3}>
        {this.renderTexts()}
      </Grid>
    );
  }
}

export default Texts;
