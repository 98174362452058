import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_LOADING_UI,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_CAPTCHA,
  SET_EMAIL_SENT
} from '../types';

const initialState = {
  authenticated: false,
  credentials: {},
  captchaVerified: false,
  emailSent: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        ...action.payload
      };
    case SET_CAPTCHA:
      return {
        ...state,
        captchaVerified: action.payload.captchaVerified
      };
    case SET_EMAIL_SENT:
      return {
        ...state,
        emailSent: action.payload.emailSent
      };
    default:
      return state;
  }
}
