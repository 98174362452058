import React, { Component } from "react";
import { Link } from "@reach/router";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getExhibitions } from "../redux/actions/dataActions";

import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Modal";
import { textAlign } from "@material-ui/system";
import Skeleton from "@material-ui/lab/Skeleton";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";

import "../sass/main.scss";
import "typeface-roboto";
import "aos/dist/aos.css";

library.add(fab);

const styles = {
  root: {
    flexGrow: 1
  },
  gridList: {
    width: 100,
    height: 450
  }
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: undefined,
      modalOpen: false,
      selectedImageUrl: ""
    };
  }

  handleImageClick = url => {
    this.setState({
      modalOpen: true,
      selectedImageUrl: url
    });
  };

  componentWillMount() {
    const { exhibitions, getExhibitions } = this.props;

    if (Object.keys(exhibitions).length === 0) {
      getExhibitions();
      /*axios
        .get('/thumbnails')
        .then(response => {
          getExhibitions();
          /*this.setState({
            images: response.data
          });
        })
        .catch(error => {
          console.log(error);
        });*/
    }
  }

  renderThumbnails = () => {
    const { exhibitions } = this.props;

    return Object.keys(exhibitions).map(folder => (
      <Grid item key={folder} xs={12} sm={6} md={4} lg={3} xl={2}>
        <Link to={folder} state={{ exhibitions: exhibitions[folder] }}>
          <img
            src={exhibitions[folder].images[exhibitions[folder].thumbnailIndex]}
            alt={exhibitions[folder].images[exhibitions[folder].thumbnailIndex]}
            className="thumbnail-image"
            key={exhibitions[folder].images[exhibitions[folder].thumbnailIndex]}
            height="200px"
            width="200px"
          />
          <div className="thumbnail-text">{folder}</div>
        </Link>
      </Grid>
    ));
  };

  renderSkeleton = () => {
    const width = window.innerWidth;

    let skeletonCount;

    if (width >= 1366) {
      skeletonCount = 12;
    } else {
      skeletonCount = 6;
    }

    return Array.from(Array(skeletonCount), (e, index) => {
      return (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
          <div className="thumbnail">
            <Skeleton
              variant="rect"
              width={200}
              height={200}
              className="thumbnail-image"
            />
          </div>
        </Grid>
      );
    });
  };

  render() {
    const { images, modalOpen } = this.state;
    const { exhibitions } = this.props;

    //let uri = this.props.location.pathname.replace('/', '');

    //var btnClass = 'btn';
    //if (this.state.isPressed) btnClass += ' btn-pressed';
    //else if (this.state.isHovered) btnClass += ' btn-over';

    /*<Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h1' component='h2' gutterBottom>
        Create Exhibition
      </Typography>
    </Grid>
    <Grid item xs={12} lg={10}>
*/
    return (
      <Grid container spacing={8}>
        {Object.keys(exhibitions).length !== 0
          ? this.renderThumbnails()
          : this.renderSkeleton()}
      </Grid>
    );
  }
}

Home.propTypes = {
  exhibitions: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  exhibitions: state.data.exhibitions
});

const mapActionsToProps = {
  getExhibitions
};

export default connect(mapStateToProps, mapActionsToProps)(Home);

/*
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            src={this.state.selectedImageUrl}
            alt={this.state.selectedImageUrl}
          />
        </Dialog>
*/
