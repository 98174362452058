import axios from 'axios';

import {
  SET_EXHIBITIONS,
  DELETED_EXHIBITION,
  ADD_EXHIBITION,
  SET_LOADING_UI,
  STOP_LOADING_UI,
  SET_SUCCESS
} from '../types';

export const getExhibitions = () => dispatch => {
  axios
    .get('/thumbnails')
    .then(response => {
      dispatch({ type: SET_EXHIBITIONS, payload: response.data });
    })
    .catch(error => {
      console.log(error);
    });
};

export const deleteExhibition = exhibitionId => dispatch => {
  //exhibitionId = exhibitionId.toLowerCase();
  console.log(`/exhibitions/${exhibitionId}`);
  axios
    .delete(`/exhibitions/${exhibitionId}`)
    .then(response => {
      console.log(response);
      dispatch({ type: DELETED_EXHIBITION, payload: exhibitionId });
    })
    .catch(error => {
      console.log(error);
    });
};

export const resetDeletedExhibition = () => dispatch => {
  dispatch({ type: DELETED_EXHIBITION, payload: null });
  // TODO: Refresh exhibitions after deletion
  //getExhibitions();
};

export const createExhibition = (
  exhibitionName,
  thumbnailIndex,
  data
) => dispatch => {
  dispatch({ type: SET_LOADING_UI, payload: { loading: true } });

  const config = {
    headers: { exhibition: exhibitionName, 'thumbnail-index': thumbnailIndex }
  };

  axios
    .post('/exhibitions/create', data, config)
    .then(response => {
      dispatch({
        type: SET_SUCCESS,
        payload: { success: true }
      });
      dispatch({ type: ADD_EXHIBITION, payload: response.data });
      setTimeout(
        () => dispatch({ type: SET_SUCCESS, payload: { success: false } }),
        3000
      );
    })
    .catch(error => {
      console.log(error);
    });
};
