import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import ImageGallery from "react-image-gallery";
import AOS from "aos";

import { Mobile, Desktop, Tablet, Default } from "../util/Breakpoints";
import clsx from "clsx";

AOS.init({
  duration: 1000
});

export class ProjectDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      tempImages: []
    };
  }

  componentDidMount() {
    this.setState({
      tempImages: this.props.location.state.exhibitions.images
    });
    let images;
    /*if (this.props.location.state === null) {
      images = {
        1: 'https://firebasestorage.googleapis.com/v0/b/bjarnisig-d1d9d.appspot.com/o/Courage%2F03.jpg?alt=media&token=7d74568d-1e11-46dc-a5c5-3c711b371b6e',
        2: 'https://firebasestorage.googleapis.com/v0/b/bjarnisig-d1d9d.appspot.com/o/Courage%2F02.jpg?alt=media&token=3db55e75-fabb-4837-a9a4-7cbb73105ccd',
        3: 'https://firebasestorage.googleapis.com/v0/b/bjarnisig-d1d9d.appspot.com/o/Courage%2F01.jpg?alt=media&token=2b7f9718-9fea-45a8-8fd4-9fbd505f6e80',
        thumbnail:
          'https://firebasestorage.googleapis.com/v0/b/bjarnisig-d1d9d.appspot.com/o/Courage%2F03.jpg?alt=media&token=7d74568d-1e11-46dc-a5c5-3c711b371b6e'
      };
    } else {*/
    images = this.props.location.state.exhibitions.images;
    // }

    let tempUrls = [];
    let tempObj = {};

    Object.keys(images).forEach(key => {
      if (key !== "thumbnail" && key !== "deleted") {
        tempObj = {};
        tempObj["original"] = images[key];
        tempObj["thumbnail"] = images[key];
        tempUrls.push(tempObj);
      }
    });

    this.setState({
      images: tempUrls
    });
  }

  render() {
    const { tempImages, images } = this.state;

    return (
      <React.Fragment>
        <Default minDeviceWidth={800}>
          <div style={{ maxWidth: "40rem" }}>
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              items={images}
            />
          </div>
        </Default>
        <Mobile minDeviceWidth={800}>
          <Grid container className="exhibition-image">
            {tempImages &&
              tempImages.map((image, index) => (
                <Grid item key={image}>
                  <div
                    className=""
                    data-aos={clsx(
                      { "fade-left": index % 2 === 0 },
                      { "fade-right": index % 2 !== 0 }
                    )}
                  >
                    <img
                      src={image}
                      alt={image}
                      className="exhibition-image__item"
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
        </Mobile>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default ProjectDetails;
