import { SET_EXHIBITIONS, DELETED_EXHIBITION, ADD_EXHIBITION } from '../types';

const initialState = {
  exhibitions: {},
  deletedExhibition: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_EXHIBITIONS:
      return {
        ...state,
        exhibitions: action.payload
      };
    case DELETED_EXHIBITION:
      return {
        ...state,
        deletedExhibition: action.payload
      };
    case ADD_EXHIBITION:
      let tempExhibitions = state.exhibitions;
      tempExhibitions[action.payload.exhibition] = action.payload.data;
      return {
        ...state,
        exhibitions: tempExhibitions
      };
    default:
      return state;
  }
}
