import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import { createExhibition } from '../redux/actions/dataActions';

import LoadingButton from '../components/LoadingButton';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import {
  fade,
  withStyles,
  makeStyles,
  createMuiTheme
} from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faShare } from '@fortawesome/free-solid-svg-icons';

export class CreateExhibition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileURLs: [],
      exhibitionName: '',
      nameError: false,
      thumbnailIndex: 0,
      addExhibitionClicked: false,
      exhibitionAddSuccess: false
    };

    this.imagesToUpload = new FormData();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.input = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.success) {
      navigate('../');
    }
  }

  handleChange = event => {
    this.setState({
      exhibitionName: event.target.value
    });
  };

  handleUpload = () => {
    const { fileURLs } = this.state;
    const files = this.input.current.files;

    Object.keys(files).forEach(key => {
      this.imagesToUpload.append('images', files[key], files[key].name);
    });

    Object.keys(files).forEach(key => {
      const reader = new FileReader();

      reader.onload = event => {
        let tempURLs = this.state.fileURLs;

        if (fileURLs.length >= 5) {
          return;
        }
        if (
          // Check for duplicate images
          fileURLs.find(url => {
            return event.target.result === url;
          })
        ) {
          return;
        }

        tempURLs.push(event.target.result);
        this.setState({
          fileURLs: tempURLs
        });
      };
      reader.readAsDataURL(files[key]);
    });
  };

  handleSubmit = event => {
    const {
      exhibitionName,
      nameError,
      thumbnailIndex,
      exhibitionAddSuccess
    } = this.state;
    event.preventDefault();

    if (exhibitionName === '') {
      this.setState({
        nameError: true
      });
      return;
    } else if (nameError) {
      this.setState({
        nameError: false
      });
    }

    this.setState({
      addExhibitionClicked: true
    });

    this.props.createExhibition(
      exhibitionName,
      thumbnailIndex,
      this.imagesToUpload
    );

    this.imagesToUpload = new FormData();
  };

  handleThumbnailClick = index => {
    this.setState({
      thumbnailIndex: index
    });
  };

  handleImageDelete = index => {
    const { fileURLs, thumbnailIndex } = this.state;
    let tempURLs = fileURLs;
    let nextThumbnailIndex = thumbnailIndex;

    tempURLs.splice(index, 1);
    console.log(fileURLs.length);
    console.log(thumbnailIndex);
    if (thumbnailIndex === index) {
      if (index === 0) {
        nextThumbnailIndex = 0;
      } else {
        nextThumbnailIndex--;
      }
    } else if (thumbnailIndex === fileURLs.length) {
      console.log(nextThumbnailIndex);
      nextThumbnailIndex--;
    }

    this.setState({
      fileURLs: tempURLs,
      thumbnailIndex: nextThumbnailIndex
    });
  };

  render() {
    const {
      fileURLs,
      nameError,
      thumbnailIndex,
      addExhibitionClicked,
      exhibitionAddSuccess
    } = this.state;
    const { UI } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h1' component='h2' gutterBottom>
            Create Exhibition
          </Typography>
        </Grid>
        <Grid item xs={12} lg={10}>
          <form
            noValidate
            onSubmit={this.handleSubmit}
            className='exhibition-form'
          >
            <TextField
              required
              id='standard-name'
              label='Name'
              className='exhibition-form__input'
              //helperText={errors.email}
              error={nameError}
              autoComplete='name'
              onChange={this.handleChange}
              disabled={addExhibitionClicked}
              style={{
                fontSize: '1rem'
              }}
              InputProps={{
                style: {
                  color: 'red'
                }
              }}
            />
            <LoadingButton
              name='Add Exhibition'
              loading={UI.loading}
              success={UI.success}
              onClick={this.handleSubmit}
            />
          </form>
        </Grid>
        <Grid item xs={12}>
          <div className='create-exhibition__input'>
            <input
              accept='image/*'
              className='create-exhibition__input'
              id='outlined-button-file'
              multiple
              type='file'
              ref={this.input}
              onChange={this.handleUpload}
              disabled={addExhibitionClicked}
            />
          </div>
          <label htmlFor='outlined-button-file'>
            <Button
              variant='outlined'
              component='span'
              className='upload-button'
              disabled={addExhibitionClicked}
            >
              Upload
              <FontAwesomeIcon
                icon={faUpload}
                className='upload-button__icon'
              />
            </Button>
          </label>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h2' component='h2' gutterBottom>
            Images
          </Typography>
        </Grid>

        {fileURLs.map((url, index) => (
          <Grid item xs={12} sm={6} md={3} lg={2} key={url}>
            <div className='image-card'>
              <img
                src={url}
                alt={url}
                className={classNames('exhibition-image', {
                  'exhibition-image__selected': thumbnailIndex === index
                })}
                height='150px'
                width='150px'
              />
              <Button
                variant='contained'
                color='secondary'
                onClick={() => this.handleImageDelete(index)}
                disabled={addExhibitionClicked}
              >
                Delete
              </Button>
              <Button
                variant='contained'
                color='primary'
                className={classNames({
                  'exhibition-image__button': thumbnailIndex === index
                })}
                onClick={() => this.handleThumbnailClick(index)}
                disabled={
                  thumbnailIndex === index
                    ? true
                    : false || addExhibitionClicked
                }
              >
                Thumbnail
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  UI: state.UI
});

const mapActionsToProps = { createExhibition };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CreateExhibition);

/*

            <GridList cellwidth={160} className='gridList' cols={3}>
              {fileURLs.map((url, index) => (
                <GridListTile key={url} cols={1} className='exhibition-images'>
                  <div className='exhibition-thumbnail'>
                    <img
                      src={url}
                      alt={url}
                      className={classNames('exhibition-image', {
                        'exhibition-image__selected': thumbnailIndex === index
                      })}
                      key={url}
                      height='150px'
                      width='150px'
                    />
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => this.handleImageDelete(index)}
                    >
                      Delete
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classNames({
                        'exhibition-image__button': thumbnailIndex === index
                      })}
                      onClick={() => this.handleThumbnailClick(index)}
                      disabled={thumbnailIndex === index ? true : false}
                    >
                      Thumbnail
                    </Button>
                  </div>
                </GridListTile>
              ))}
            </GridList>

*/
