import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, TextField, Grid, Button } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CircularProgress from '@material-ui/core/CircularProgress';

import { submitCaptcha, sendEmail } from '../redux/actions/userActions';

import { Mobile, Default } from '../util/Breakpoints';

import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone
} from '@fortawesome/free-solid-svg-icons';

import bjarni from '../media/bjarni.png';

import captchaKeys from '../keys/captcha.json';

export class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      text: '',
      errors: {},
      recaptcha: null,
      loading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.emailSent) {
      this.setState({ loading: false });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    const { name, email, text, recaptcha } = this.state;
    const { sendEmail, emailSent } = this.props;

    event.preventDefault();

    if (emailSent) {
      return;
    }

    let errors = {};

    if (name === '') {
      errors.name = true;
    } else {
      delete errors.name;
    }
    if (email === '') {
      errors.email = true;
    } else {
      delete errors.email;
    }
    if (text === '') {
      errors.text = true;
    } else {
      delete errors.text;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors
      });
      return;
    } else {
      this.setState({
        errors: {}
      });
    }

    this.setState({
      loading: true
    });

    if (!recaptcha === '') {
    }

    var data = {
      name: name,
      email: email,
      text: text
    };

    sendEmail(data);
  };

  handleRecaptcha = value => {
    const { submitCaptcha } = this.props;

    this.setState({
      recaptcha: value
    });

    const data = {
      //secret: captchaKeys.secret,
      response: value
    };

    if (value !== null) {
      submitCaptcha(data);
    }
  };

  renderInformation = () => {
    return (
      <div className='contact-information'>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Grid item style={{ paddingBottom: '5rem' }}>
            <Avatar
              alt='Bjarni Sigurbjörsson'
              src={bjarni}
              className='contact-information__image'
            />
            <p>
              Feel free to contact me regarding exhibitions, paintings, workshop
              or any other inquiries either through this form or you can send me
              a line directly to my mail.
            </p>
          </Grid>
          <Grid
            container
            item
            direction='column'
            justify='center'
            alignItems='flex-start'
          >
            <Grid item style={{ marginBottom: '1.3rem' }}>
              <a
                href={'mailto:bjarni@bjarnisig.com'}
                className='contact-information__item'
              >
                <FontAwesomeIcon
                  className='contact-information__icon'
                  icon={faEnvelope}
                  size='lg'
                  style={{ marginRight: '2rem' }}
                />
                bjarni@bjarnisig.com
              </a>
            </Grid>
            <Grid item style={{ marginBottom: '1.3rem' }}>
              <a href='tel:(+354)8993618' className='contact-information__item'>
                <FontAwesomeIcon
                  className='contact-information__icon'
                  icon={faPhone}
                  size='lg'
                  style={{ marginRight: '2rem' }}
                />
                (+354) 899-3618
              </a>
            </Grid>
            <Grid item>
              <a
                href='https://goo.gl/maps/ftFaWpx1ErJTbVNy8'
                className='contact-information__item'
              >
                <FontAwesomeIcon
                  className='contact-information__icon'
                  icon={faMapMarkerAlt}
                  size='lg'
                  style={{ marginRight: '2.5rem' }}
                />
                Kársnesbraut 102
              </a>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderForm = () => {
    const { errors, loading } = this.state;
    const { captchaVerified, emailSent } = this.props;

    return (
      <form
        className='contact__form'
        noValidate
        onSubmit={this.handleSubmit}
        method='POST'
      >
        <Grid item xs={12}>
          <TextField
            id='standard-name'
            label='Name'
            name='name'
            margin='normal'
            variant='outlined'
            fullWidth
            onChange={this.handleChange}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='outlined-email-input'
            label='Email'
            type='email'
            name='email'
            autoComplete='email'
            margin='normal'
            variant='outlined'
            fullWidth
            onChange={this.handleChange}
            error={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='standard-multiline-flexible'
            label='Text'
            name='text'
            multiline
            rowsMax='4'
            margin='normal'
            variant='outlined'
            fullWidth
            onChange={this.handleChange}
            error={errors.text}
          />
        </Grid>
        <Grid item xs={12}>
          <ReCAPTCHA
            sitekey={captchaKeys.site}
            onChange={this.handleRecaptcha}
            style={{ marginTop: '2rem' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color={emailSent ? 'primary' : 'default'}
            variant='contained'
            className='contact__button'
            type='submit'
            disabled={loading}
          >
            {emailSent ? 'Message Sent!' : 'Send'}
            {loading && (
              <CircularProgress size={24} className='login-input__loading' />
            )}
          </Button>
        </Grid>
      </form>
    );
  };
  //disabled={!captchaVerified || loading}

  render() {
    return (
      <div className='contact'>
        <Grid container>
          <Mobile>
            <Grid item xs={12}>
              {this.renderInformation()}
            </Grid>
            <Grid item xs={12}>
              {this.renderForm()}
            </Grid>
          </Mobile>
          <Default>
            <Grid item xs={6}>
              {this.renderInformation()}
            </Grid>
            <Grid item xs={6}>
              {this.renderForm()}
            </Grid>
          </Default>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  UI: state.UI,
  user: state.user,
  captchaVerified: state.user.captchaVerified,
  emailSent: state.user.emailSent
});

const mapActionsToProps = { submitCaptcha, sendEmail };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Contact);
