import React, { Component } from "react";
import { Router, Redirect } from "@reach/router";
import axios from "axios";
import jwtDecode from "jwt-decode";

import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser } from "./redux/actions/userActions";

import ExhibitionDetails from "./components/ProjectDetails";
import Sidebar from "./components/Sidebar";
import AdminSidebar from "./components/AdminSidebar";
import { ScrollToTop } from "./components/ScrollToTop";

import CreateExhibition from "./pages/CreateExhibition";
import Home from "./pages/Home";
import AdminHome from "./pages/AdminHome";
import Login from "./pages/Login";
import CV from "./pages/CV";
import Contact from "./pages/Contact";
import Workshop from "./pages/Workshop";
import Texts from "./pages/Texts";

import "react-image-gallery/styles/css/image-gallery.css";
import CssBaseline from "@material-ui/core/CssBaseline";

import AuthRoute from "./util/AuthRoute";

//axios.defaults.baseURL = 'http://localhost:5000/bjarnisig-d1d9d/europe-west1/api/';
axios.defaults.baseURL =
  // "http://localhost:5000/bjarnisig-d1d9d/europe-west1/api";
  "https://europe-west1-bjarnisig-d1d9d.cloudfunctions.net/api";

const token = localStorage.FBIdToken;

if (token) {
  const decodedToken = jwtDecode(token);

  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = "/admin";
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    //store.dispatch(getUserData());
  }
}

export class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <CssBaseline>
          <Router primary={false}>
            <ScrollToTop path="/">
              <Redirect noThrow from="/" to="/exhibitions" />
              <Sidebar path="/" state={{ type: "artist" }}>
                <Home path="/exhibitions" />
                <ExhibitionDetails path="exhibitions/:projectName" />
                <Workshop path="/workshop" />
                <CV path="/cv" />
                <Texts path="/texts" />
                <Contact path="/contact" />
              </Sidebar>

              <Login path="/login" />
              <AdminSidebar path="/admin" state={{ type: "admin" }}>
                <AdminHome path="/" />
                <CreateExhibition path="/exhibition/create" />
              </AdminSidebar>
            </ScrollToTop>
          </Router>
        </CssBaseline>
      </Provider>
    );
  }
}
//<AuthRoute path='admin' navigate='admin' />
export default App;
