import React, { useState } from "react";
import { Link } from "@reach/router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import classNames from "classnames";

import "./AdminSidebar";

library.add(fab);

export default props => {
  const [hover, setHover] = useState("exhibitions");
  const { type } = props.state;
  const artistCategories = [
    "exhibitions",
    "paintings",
    "cv",
    "courses",
    "contact"
  ];
  const adminCategories = [];
  /*let navClass = classNames({
    btn: true,
    'btn-pressed': this.state.isPressed,
    'btn-over': !this.state.isPressed && this.state.isHovered
  });*/
  //console.log(hover);
  const hoverNav = e => {
    let value = e.target.innerText;

    setHover(e.target.innerText);
  };
  const categories = ["exhibitions", "workshop", "cv", "texts", "contact"];
  return (
    <div className="admin-container">
      <div className="content">
        <nav className="admin-sidebar">
          <header className="admin-header">
            <Link to="./">
              <h1 className="heading">Control Panel</h1>
            </Link>
          </header>
          <ul className="side-nav">
            <li className="side-nav__item">
              <Link
                to="exhibitions"
                className={classNames({
                  "side-nav__link": true,
                  "side-nav__click": hover === "EXHIBITIONS"
                })}
              >
                <span onMouseDown={hoverNav} value="exhibitions">
                  exhibitions
                </span>
              </Link>
            </li>
            <li className="side-nav__item">
              <Link to="workshop" className="side-nav__link">
                <span onMouseEnter={e => setHover(e.target.value)}>
                  workshop
                </span>
              </Link>
            </li>
            <li className="side-nav__item">
              <Link to="contact" className="side-nav__link">
                <span onMouseEnter={e => setHover(e.target.value)}>
                  contact
                </span>
              </Link>
            </li>
          </ul>
        </nav>
        <main className="admin-content-view">{props.children}</main>
      </div>
    </div>
  );
};
