import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ScheduleIcon from "@material-ui/icons/Schedule";

import PictureOne from "../media/1.jpg";
import PictureTwo from "../media/2.jpg";
import PictureThree from "../media/3.jpg";
import PictureFour from "../media/4.jpg";
import PictureFive from "../media/5.jpg";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

export default () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const renderFirstCourse = () => {
    return (
      <Grid item>
        <Card className={classes.card}>
          <CardHeader
            title="Art With Emphasization On The Painting"
            subheader="September 16th - November 25th"
          />
          <CardMedia
            className={classes.media}
            image={PictureOne}
            title="Art with emphasization on the painting"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ fontSize: "1.5rem" }}
            >
              Mondays
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: "1.3rem", fontWeight: "bold" }}
            >
              {" "}
              <br />
              Note: Materials are not included in the price.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <ScheduleIcon style={{ fontSize: "2rem" }} />
            <Typography style={{ fontSize: "1.3rem", marginLeft: ".5rem" }}>
              18:00 - 21:00
            </Typography>
            <AttachMoneyIcon style={{ marginLeft: "auto", fontSize: "2rem" }} />
            <Typography style={{ fontSize: "1.3rem", marginLeft: ".25rem" }}>
              60.000 kr.
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const renderSecondCourse = () => {
    return (
      <Grid item>
        <Card className={classes.card}>
          <CardHeader
            title="Art With Emphasization On Paper, Drawings, Watercolors And Ink"
            subheader="September 17th - November 26th"
          />
          <CardMedia
            className={classes.media}
            image={PictureTwo}
            title="Art With Emphasization On Paper, Drawings, Watercolors And Ink"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ fontSize: "1.5rem" }}
            >
              Tuesdays
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: "1.3rem", fontWeight: "bold" }}
            >
              {" "}
              <br />
              Note: Materials are not included in the price.
            </Typography>
          </CardContent>

          <CardActions disableSpacing>
            <ScheduleIcon style={{ fontSize: "2rem" }} />
            <Typography style={{ fontSize: "1.3rem", marginLeft: ".5rem" }}>
              18:00 - 21:00
            </Typography>
            <AttachMoneyIcon style={{ marginLeft: "auto", fontSize: "2rem" }} />
            <Typography
              align="right"
              style={{ fontSize: "1.3rem", marginLeft: ".25rem" }}
            >
              60.000 kr.
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    );
  };
  const renderThirdCourse = () => {
    return (
      <Grid item>
        <Card className={classes.card}>
          <CardHeader
            title="Art Seminar"
            subheader="September 18th - November 27th"
          />
          <CardMedia
            className={classes.media}
            image={PictureThree}
            title="Course Three"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ fontSize: "1.5rem" }}
            >
              Wednesdays
              <br />
              Emphasization on reading & reviewing texts in art philosophy,
              exhibition discussions and criticism on participants paintings.
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: "1.3rem", fontWeight: "bold" }}
            >
              {" "}
              <br />
              Note: Materials are not included in the price.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <ScheduleIcon style={{ fontSize: "2rem" }} />
            <Typography style={{ fontSize: "1.3rem", marginLeft: ".5rem" }}>
              18:00 - 21:00
            </Typography>
            <AttachMoneyIcon style={{ marginLeft: "auto", fontSize: "2rem" }} />
            <Typography
              align="right"
              style={{ fontSize: "1.3rem", marginLeft: ".25rem" }}
            >
              60.000 kr.
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    );
  };
  const renderFourthCourse = () => {
    return (
      <Grid item>
        <Card className={classes.card}>
          <CardHeader
            title="Master Class Weekend Course 1"
            subheader="October 3rd - October 6th"
          />
          <CardMedia
            className={classes.media}
            image={PictureFour}
            title="Master Class Weekend Course 1"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ fontSize: "1.5rem", textDecoration: "underline" }}
            >
              Thursdays to Sundays
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: "1.3rem", fontWeight: "bold" }}
            >
              {" "}
              <br />
              Note: Materials are not included in the price.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <ScheduleIcon style={{ fontSize: "2rem" }} />
            <Typography style={{ fontSize: "1.3rem", marginLeft: ".5rem" }}>
              09:00 - 17:00
            </Typography>
            <AttachMoneyIcon style={{ marginLeft: "auto", fontSize: "2rem" }} />
            <Typography
              align="right"
              style={{ fontSize: "1.3rem", marginLeft: ".25rem" }}
            >
              60.000 kr.
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    );
  };
  const renderFifthCourse = () => {
    return (
      <Grid item>
        <Card className={classes.card}>
          <CardHeader
            title="Master Class Weekend Course 2"
            subheader="November 7th - November 10th"
          />
          <CardMedia
            className={classes.media}
            image={PictureFive}
            title="Master Class Weekend Course 2"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ fontSize: "1.3rem" }}
            >
              Thursdays to Sundays
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: "1.3rem", fontWeight: "bold" }}
            >
              {" "}
              <br />
              Note: Materials are not included in the price.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <ScheduleIcon style={{ fontSize: "2rem" }} />
            <Typography style={{ fontSize: "1.3rem", marginLeft: ".5rem" }}>
              09:00 - 17:00
            </Typography>
            <AttachMoneyIcon style={{ marginLeft: "auto", fontSize: "2rem" }} />
            <Typography style={{ fontSize: "1.3rem", marginLeft: ".25rem" }}>
              60.000 kr.
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    );
  };
  return (
    <div>
      <Grid container spacing={4}>
        {renderFirstCourse()}
        {renderSecondCourse()}
        {renderThirdCourse()}
        {renderFourthCourse()}
        {renderFifthCourse()}
      </Grid>
    </div>
  );
};
