import {
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_LOADING_UI,
  STOP_LOADING_UI,
  SET_SUCCESS
} from '../types';

const initialState = {
  loading: false,
  errors: null,
  success: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null
      };
    case SET_LOADING_UI:
      return {
        ...state,
        loading: action.payload.loading
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false
      };
    case SET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success
      };
    default:
      return state;
  }
}
