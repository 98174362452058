import React, { useState } from "react";
import { Link } from "@reach/router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import classNames from "classnames";
import clsx from "clsx";

library.add(fab);

export default props => {
  const [hover, setHover] = useState(window.location.pathname.substr(1));
  const [sidebar, setSidebar] = useState(false);
  const { type } = props.state;
  const categories = ["exhibitions", "workshop", "cv", "texts", "contact"];
  //const categories = [];
  /*let navClass = classNames({
    btn: true,
    'btn-pressed': this.state.isPressed,
    'btn-over': !this.state.isPressed && this.state.isHovered
  });*/
  //console.log(hover);
  const hoverNav = e => {
    let value = e.target.innerText;
    value = value.toLowerCase();
    setHover(value);
  };

  const handleSidebarClick = () => {
    setSidebar(!sidebar);
  };

  return (
    <div className="container">
      <div
        className={clsx("hamburger-content", {
          "hamburger-content__open": sidebar
        })}
      >
        <button
          className={clsx("hamburger hamburger--collapse-r", {
            "hamburger--collapse is-active": sidebar
          })}
          type="button"
          onClick={handleSidebarClick}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        {sidebar && (
          <span className="hamburger-nav">
            {categories.map(category => {
              return (
                <li
                  className="hamburger-nav__item"
                  key={category}
                  onClick={handleSidebarClick}
                >
                  <Link
                    to={category}
                    className={classNames({
                      "hamburger-nav__link": true,
                      "": hover === { category }
                    })}
                  >
                    <span onClick={hoverNav} value={category}>
                      {category}
                    </span>
                  </Link>
                </li>
              );
            })}
          </span>
        )}
      </div>
      <div className="header-bar"></div>

      <header className="header">
        <Link to="/">
          <h1 className="heading" id="heading">
            Bjarni Sigurbjörnsson{" "}
          </h1>
        </Link>
      </header>

      <div className="content">
        <nav className="sidebar">
          <ul className="side-nav">
            {categories.map(category => {
              return (
                <li
                  className={classNames({
                    "side-nav__item": true,
                    "side-nav__item--active": hover === category
                  })}
                  key={category}
                  onClick={hoverNav}
                >
                  <Link
                    to={category}
                    className={classNames({
                      "side-nav__link": true
                    })}
                  >
                    <span value={category}>{category}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="socialMedia">
            <div className="socialMedia-item">
              <a
                href="https://www.facebook.com/bjarnisigurbjornssonartist/"
                className="socialMedia-item--link"
              >
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  className=".socialMedia-item"
                  size="lg"
                />
              </a>
            </div>
            <div className="socialMedia-item">
              <a
                href="https://www.facebook.com/bjarnisigurbjornssonartist/"
                className="socialMedia-item--link"
              >
                <FontAwesomeIcon
                  icon={["fab", "facebook"]}
                  className=".socialMedia-item"
                  size="lg"
                />
              </a>
            </div>
          </div>
          <div className="legal">
            &copy; 2019 by Bjarni Sigurbjörnsson. All rights reserved.
          </div>
        </nav>
        <main className="content-view">{props.children}</main>
      </div>
    </div>
  );
};
