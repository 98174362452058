import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, ...rest }) => {
  if (this.props.authenticated) {
    return <Redirect noThrow to='cp/home' />;
  } else {
    return <Component />;
  }
};

AuthRoute.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(AuthRoute);
