// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const SET_CAPTCHA = 'SET_CAPTCHA';
export const SET_EMAIL_SENT = 'SET_EMAIL_SENT';

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_LOADING_UI = 'SET_LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SET_SUCCESS = 'SET_SUCCESS';

// Data reducer types
export const SET_EXHIBITIONS = 'SET_EXHIBITIONS';
export const ADD_EXHIBITION = 'ADD_EXHIBITION';
export const DELETED_EXHIBITION = 'DELETED_EXHIBITION';
export const SET_PAINTINGS = 'SET_PAINTINGS';
export const SET_CV = 'SET_CV';
export const SET_COURSES = 'SET_COURSES';
