import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "@reach/router";

import {
  getExhibitions,
  deleteExhibition,
  resetDeletedExhibition
} from "../redux/actions/dataActions";

import Grid from "@material-ui/core/Grid";
import GridListTile from "@material-ui/core/GridListTile";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

class AdminHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: {},
      deleteModalOpen: false,
      exhibitionToDelete: "",
      confirmClicked: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { deletedExhibition, resetDeletedExhibition } = this.props;

    if (
      nextProps.deletedExhibition !== null &&
      nextProps.deletedExhibition !== deletedExhibition
    ) {
      setTimeout(() => {
        resetDeletedExhibition();
        this.setState({
          confirmClicked: false,
          deleteModalOpen: false
        });
      }, 3000);
    }
  }

  componentWillMount() {
    this.props.getExhibitions();
    /*axios
      .get('/thumbnails')
      .then(response => {
        this.setState({
          images: response.data
        });
      })
      .catch(error => {
        console.log(error);
      });*/
  }

  handleDelete = (event, folder) => {
    this.setState({
      deleteModalOpen: true,
      exhibitionToDelete: folder
    });
    /*return (

    );*/
  };

  handleClose = () => {
    if (!this.state.confirmClicked) {
      this.setState({
        deleteModalOpen: false,
        confirmClicked: false
      });
    }
  };

  handleSubmitDelete = exhibition => {
    this.setState({
      confirmClicked: true
    });
    this.props.deleteExhibition(this.state.exhibitionToDelete);
  };

  render() {
    const { deleteModalOpen, confirmClicked } = this.state;
    const { authenticated, exhibitions, deletedExhibition } = this.props;

    if (!authenticated) {
      return <Redirect noThrow to="/login" />;
    }

    return (
      <React.Fragment>
        <Button variant="contained" className="create__button" size="large">
          <Link to="exhibition/create">Create Exhibition</Link>
        </Button>
        <Grid container spacing={4}>
          {exhibitions &&
            Object.keys(exhibitions).map(folder => (
              <Grid item key={folder} xs={12} sm={6} md={4} lg={2}>
                <div className="thumbnail">
                  <img
                    src={
                      exhibitions[folder].images[
                        exhibitions[folder].thumbnailIndex
                      ]
                    }
                    alt={
                      exhibitions[folder].images[
                        exhibitions[folder].thumbnailIndex
                      ]
                    }
                    className="thumbnail-image"
                    key={
                      exhibitions[folder].images[
                        exhibitions[folder].thumbnailIndex
                      ]
                    }
                    height="160px"
                    width="150px"
                    /*onClick={() =>
                      this.handleImageClick(exhibitions[folder][0].url)
                    }*/
                  />
                  <div className="thumbnail-text">{folder}</div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className=""
                      onClick={e => this.handleDelete(e, folder)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </Grid>
            ))}
          <Dialog
            open={deleteModalOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: ""
            }}
          >
            <div className="dialog-delete">
              {!deletedExhibition && (
                <DialogTitle
                  className="dialog-delete__title-delete"
                  id="alert-dialog-title"
                >
                  {"Delete this exhibition?"}
                </DialogTitle>
              )}
              {deletedExhibition && (
                <DialogTitle
                  className="dialog-delete__title-success"
                  id="alert-dialog-title"
                >
                  {"Success!"}
                </DialogTitle>
              )}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you wish to delete this exhibition forever
                  Bjarni? There is no turning back!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  color="primary"
                  autoFocus
                  disabled={confirmClicked}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleSubmitDelete}
                  color="secondary"
                  disabled={confirmClicked}
                >
                  Confirm
                  {confirmClicked && !deletedExhibition && (
                    <CircularProgress
                      size={30}
                      className="login-input__loading"
                    />
                  )}
                  {deletedExhibition && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="dialog-delete__icon"
                    />
                  )}
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="dialog-delete__icon"
                  />
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Grid>
      </React.Fragment>
    );
  }
}

/*
          <Dialog
            aria-labelledby='simple-dialog-title'
            open={false}
            onClose={() => this.setState({ modalOpen: false })}
            style={{
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={this.state.selectedImageUrl}
              alt={this.state.selectedImageUrl}
            />
          </Dialog>
*/

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
  exhibitions: state.data.exhibitions,
  deletedExhibition: state.data.deletedExhibition
});

const mapActionsToProps = {
  getExhibitions,
  deleteExhibition,
  resetDeletedExhibition
};

export default connect(mapStateToProps, mapActionsToProps)(AdminHome);

/*

<main className='content-view'>
        {' '}
        <Link to='exhibition/create'>
          <Button variant='contained' className='create__button' size='large'>
            Create Exhibition
          </Button>
        </Link>
        {!exhibitions && <CircularProgress />}
        <GridList cellHeight={220} className='gridList' cols={3} spacing={8}>
          {exhibitions &&
            Object.keys(exhibitions).map(folder => (
              <GridListTile key={folder} cols={1}>
                <div className='thumbnail'>
                  <img
                    src={
                      exhibitions[folder].images[
                        exhibitions[folder].thumbnailIndex
                      ]
                    }
                    alt={
                      exhibitions[folder].images[
                        exhibitions[folder].thumbnailIndex
                      ]
                    }
                    className='thumbnail-image'
                    key={
                      exhibitions[folder].images[
                        exhibitions[folder].thumbnailIndex
                      ]
                    }
                    height='160px'
                    width='150px'
                    />
                    <div className='thumbnail-text'>{folder}</div>
                    <Link
                      to={`${folder}/edit`}
                      state={{ exhibitions: exhibitions[folder] }}
                    >
                      <Button variant='contained' color='primary' className=''>
                        Edit
                      </Button>
                    </Link>
                    <Button
                      variant='contained'
                      color='secondary'
                      className=''
                      onClick={e => this.handleDelete(e, folder)}
                    >
                      Delete
                    </Button>
                  </div>
                </GridListTile>
              ))}
            <Dialog
              open={deleteModalOpen}
              onClose={this.handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              style={{
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: ''
              }}
            >
              <div className='dialog-delete'>
                {!deletedExhibition && (
                  <DialogTitle
                    className='dialog-delete__title-delete'
                    id='alert-dialog-title'
                  >
                    {'Delete this exhibition?'}
                  </DialogTitle>
                )}
                {deletedExhibition && (
                  <DialogTitle
                    className='dialog-delete__title-success'
                    id='alert-dialog-title'
                  >
                    {'Success!'}
                  </DialogTitle>
                )}
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Are you sure you wish to delete this exhibition forever
                    Bjarni? There is no turning back!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleClose}
                    color='primary'
                    autoFocus
                    disabled={confirmClicked}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.handleSubmitDelete}
                    color='secondary'
                    disabled={confirmClicked}
                  >
                    Confirm
                    {confirmClicked && !deletedExhibition && (
                      <CircularProgress
                        size={30}
                        className='login-input__loading'
                      />
                    )}
                    {deletedExhibition && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className='dialog-delete__icon'
                      />
                    )}
                    <FontAwesomeIcon
                      icon={faTrash}
                      className='dialog-delete__icon'
                    />
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </GridList>
        </main>

*/
